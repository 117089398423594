import React, { ReactElement } from 'react'
import { Container } from '../atoms/container'

export function Footer(): ReactElement {
  return (
    <footer className="font-poppins text-white bg-gray-dark items-center  p-6 w-full bottom-0">
      <div className="hidden md:flex">
        <Container>
          <div className="flex my-12">
            <div className="mx-8 lg:mx-12 text-sm">
              Copyright © 2022 dyrector.io | All rights reserved.
            </div>
          </div>
        </Container>
      </div>
    </footer>
  )
}
