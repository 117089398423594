import clsx from 'clsx'
import * as React from 'react'
import Logo from '../../images/logo.svg'
import {Link} from '../atoms/link'
import {Footer} from '../templates/footer'
import {MetaHelmet} from '../templates/meta-helmet'
import {Cookie} from './cookie'
import {Header} from './header'
import {Navbar} from './navbar'

interface LayoutProps {
  isBackgroungGray?: boolean
}

export function Layout({children, isBackgroungGray}: React.PropsWithChildren<LayoutProps>) {
  const backgroundColor = isBackgroungGray ? 'bg-gray-bg' : 'bg-white'
  return (
    <>
      <MetaHelmet></MetaHelmet>
      <Header className="medium:fixed transform-gpu bg-white z-10 w-full">
        <Link to="/" activeClassName="border-none">
          <img src={Logo} alt="dyrector.io's dark logo" />
        </Link>
        <div className="hidden medium:block">
          <Navbar />
        </div>
      </Header>
      <main className={clsx('flex flex-col font-poppins pt-8 medium:pt-40', backgroundColor)}>
        <Cookie />
        {children}
      </main>
      <Footer></Footer>
    </>
  )
}
