import React, {ReactElement} from 'react'
import {Container} from '../components/atoms/container'
import {Heading} from '../components/atoms/heading'

interface Props {}

export function Contact(_props: Props): ReactElement {
  return (
    <div id="contact" className="bg-gray-bg">
      <Container component="section" className="text-center mt-16">
        <Heading
          component="h1"
          underline
          subTitle={{
            first: "While we're good with smoke signals, there are simpler ways for us to ",
            second: 'get in touch and answer your questions.',
          }}
        >
          Contact
        </Heading>
      </Container>
      <Container className="my-16 mb-16 text-base font-normal text-gray text-center">hello[at]dyrector.io</Container>
    </div>
  )
}

export default Contact
