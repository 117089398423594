import {StaticImage} from 'gatsby-plugin-image'
import React from 'react'
import {Button} from '../components/atoms/button'
import {Column} from '../components/atoms/column'
import {Container} from '../components/atoms/container'
import {Heading} from '../components/atoms/heading'
import {LabeledIcon} from '../components/atoms/labeled-icon'
import {Paragraph} from '../components/atoms/paragraph'
import {Row} from '../components/atoms/row'
import {SkillItem} from '../components/atoms/skills-item'
import {LoveItem} from '../components/molecules/love-item'
import {AreYouCuriousAboutHowWeWork} from '../components/templates/are-you-curious'
import {Layout} from '../components/templates/layout'
import EasyDeploymentIcon from '../images/icons/love-icons/easy-icon.svg'
import FasterIcon from '../images/icons/love-icons/faster-icon.svg'
import FasterRoiIcon from '../images/icons/love-icons/faster-roi-icon.svg'
import ReleaseNotesIcon from '../images/icons/love-icons/internal-icon.svg'
import ReduceCostIcon from '../images/icons/love-icons/reduce-icon.svg'
import TransparencyIcon from '../images/icons/love-icons/transparency-icon.svg'
import LoveIcon from '../images/icons/love-icons/love-icon.svg'
import IBMIcon from '../images/partners/partner_001.png'
import SuniliumIcon from '../images/partners/partner_002.png'
import HanoverIcon from '../images/partners/partner_003.png'
import BishopIcon from '../images/partners/partner_004.png'
import MicrosoftIcon from '../images/partners/partner_005.png'
import PentaIcon from '../images/partners/partner_006.png'
import NovatronicIcon from '../images/partners/partner_007.png'
import ClutchIcon from '../images/providers/provider-clutch.jpg'
import CrunchBaseIcon from '../images/providers/provider-cb.jpg'
import DreamJobsIcon from '../images/providers/provider-dreamjobs.jpg'
import SuperBIcon from '../images/providers/provider-superb.jpg'
import {Contact} from './contact'

function Index() {
  return (
    <Layout>
      <Container id="home" component="section" className="mb-6">
        <Row className="space-x-7">
          <Column className="lg:w-5/12 md:w-full lg:order-1 order-2 items-start">
            <Heading component="h3" className="leading-tight text-blue mb-4">
              You take care of your clients, we do the DevOps for you
            </Heading>

            <h6 className="mb-4 text-gray font-bold">DevOps is our speciality, and we are here to help you.</h6>

            <Paragraph className="mb-8">
              Regardless of your technical stack, we're ready to containerize your apps & services, configure your
              Kubernetes clusters and plumb in your CI/CD pipelines.
            </Paragraph>
            <Paragraph className="mb-8">
              These are significant steps for higher efficiency, happier customers and secure applications that fit
              all-around expectations.
            </Paragraph>
          </Column>

          <Column className="lg:w-7/12 md:w-full lg:order-2 order-1">
            <StaticImage
              src="../images/illustrations/home-hero-illustration.webp"
              alt="A directors chair next to a screen full of content"
              placeholder="none"
              layout="constrained"
              quality={100}
              className="md:max-w-xl lg:max-w-2xl mx-auto mb-16 lg:mb-0"
            />
          </Column>
        </Row>
      </Container>

      {/* Partners */}
      <section className="bg-wave-atom py-8 mt-8">
        <Container className="flex flex-col text-center my-8">
          <Paragraph className="text-gray-100">
            Robust software development can be a complex and time-consuming process. Organizations involved in software
            development often occupy developers to handle this complex but completely automatable process. This practice
            burns a lot of money that organizations could allocate to the actual development of their product.
            <br />
            <br />
            On another hand companies have a hard time to hire DevOps personnel due to its broad technical and cultural
            approach.
          </Paragraph>

          <Paragraph className="text-gray-100">
            As a DevOps-as-a-service provider, our purpose is to support organizations’ operational efforts with a clear
            and straightforward dashboard that’s integrated to various softwares and tools. This way our clients gain
            continuous feedback on the functionality of the software, resulting less downtime and supporting operational
            efforts.
          </Paragraph>
        </Container>
      </section>

      {/* SERVICES */}
      <Container id="services" component="section" className="text-center my-16">
        <Heading component="h2" underline className="mb-10">
          Services
        </Heading>

        {/* Robust software delivery */}
        <Row className="my-8">
          <Column className="max-w-sm w-11/12 md:w-1/2 py-4 md:px-4 order-2 md:order-1 items-end">
            <Heading component="h3" variant="h2" className="mb-4 leading-snug">
              Continuous Integration & Delivery
            </Heading>

            <Paragraph className="w-full text-justify">
              Not only your developers have a headache due to repeated steps of tests and deployments but those hurt
              your budget, as well. Without CI/CD, your company’s burning valuable time and money your teammates could
              use to improve your product. Establishing proper CI/CD integration we help drive efficiency improvements
              and by reducing time and money spent on deployments.
            </Paragraph>
          </Column>

          <Column className="w-11/12 md:w-1/2 py-4 md:px-4 order-1 md:order-2">
            <StaticImage
              src="../images/illustrations/robust-software-illustration.webp"
              alt="Robust software delivery"
              layout="constrained"
              placeholder="none"
              quality={100}
              width={527}
            />
          </Column>
        </Row>

        {/* Deep Atlassian Jira support */}
        <Row className="my-8">
          <Column className="w-11/12 md:w-1/2 py-4 md:px-4">
            <StaticImage
              src="../images/illustrations/unique-product-illustration.webp"
              alt="Deep Atlassian Jira support"
              layout="constrained"
              placeholder="none"
              quality={100}
              width={527}
            />
          </Column>

          <Column className="max-w-sm w-11/12 md:w-1/2 py-4 md:px-4 items-start">
            <Heading component="h3" variant="h2" className="mb-4 leading-snug">
              Containerization
            </Heading>

            <Paragraph className="max-w-lg text-justify">
              Your applications are vulnerable fragile as virtual machines (VMs). Your applications are vunerable when
              running solely within a virtual machine host environment?
              <br />
              <br /> VMs waste costly host resources and are much more difficult to maintain compared with containerized
              environments. They represent a significant risk to your business when they fail. Your product is more
              stable as a container. We will help you with containerization.
            </Paragraph>
          </Column>
        </Row>

        {/* Unique product building with branding */}
        <Row className="my-8">
          <Column className="max-w-sm w-11/12 md:w-1/2 py-4 md:px-4 items-start  order-2 md:order-none">
            <Heading component="h3" variant="h2" className="max-w-lg mb-4 leading-snug">
              Kubernetes
            </Heading>

            <Paragraph className="max-w-lg text-justify">
              Sorting out your containers is difficult when it comes to release management. The more complex your
              application is, the more relevant container orchestration becomes. <br />
              <br />
              Kubernetes is an open-source tool that provides portability and multi-cloud capability. Besides these
              advantages Kubernetes enables teams to scale and deploy faster than ever. We’ll set it up for you.
            </Paragraph>
          </Column>

          <Column className="w-11/12 md:w-1/2 py-4 md:px-4 order-1 md:order-none">
            <StaticImage
              src="../images/illustrations/target-container-illustration.webp"
              alt="Unique product building with branding"
              layout="constrained"
              placeholder="none"
              quality={100}
              width={527}
            />
          </Column>
        </Row>
      </Container>

      <section className="p-16  bg-gray-bg">
        <Heading component="h2" className="text-center" underline>
          Why you'll love us?
        </Heading>

        {/* SMALL ITEMS */}
        <div className="flex flex-col mt-24 medium:mx-16 items-center">
          <div className="flex flex-col medium:flex-row">
            <LoveItem
              icon={FasterIcon}
              iconAlt="Cloud Migration"
              title="Cloud Migration"
              description="Your on-prem and private servers no longer offer the flexibility your business needs to improve your operations. 

              Missing out on flexibility is the fastest way to lose customers.  Migrating your data centres to cloud providers – like AWS, Microsoft Azure or Google Cloud – gives your organization the resiliency to stay competitive. "
            />
            <LoveItem
              icon={ReduceCostIcon}
              iconAlt="Cost Optimalization"
              title="Cost Optimalization"
              description="Getting lost in the abyss of your costs is the shortest way towards overspending. 

              Your costs can get off the grid by spending too much on tools you need to get things done, as well as having costly internal processes that might be unnecessary.  We’ll look at your ecosystem and gather suggestions of cost-effective alternatives of your tools and operations. "
            />
            <LoveItem
              icon={TransparencyIcon}
              iconAlt="SDLC Improvement"
              title="SDLC Improvement"
              description="When done wrong, software development lifecycle (SDLC) can include extra steps that unnecessarily waste everyone’s time and the organization’s money. 

              This isn’t just an operational but also a cultural problem that leads to employee satisfaction plummeting, as well as costs rising and missing deadlines. We’ll check your SDLC routines and recommend solutions to shave off time and money your team can spend on to create direct value instead. "
            />
          </div>
          <div className="flex flex-col medium:flex-row">
            <LoveItem
              icon={EasyDeploymentIcon}
              iconAlt="Workshops"
              title="Workshops"
              description="You want to start your own DevOps venture but don’t know where to start. 

              Your biggest challenge is DevOps isn’t a product – it’s a cultural approach.  We’re here to help you take the first steps towards DevOps adoption. "
            />
            <LoveItem
              icon={ReleaseNotesIcon}
              iconAlt="Release Management"
              title="Release Management"
              description="Tracking the versions of your product turns more challenging as your application’s complexity increases. 

              This can exponentially increase the length and costs of deployments. In other words, deployments burn both time and money. Our release management tool, dyrector.io simplifies deployments to a few steps, making developers unnecessary to monitor and control the process. "
            />
            <LoveItem
              icon={FasterRoiIcon}
              iconAlt="Architecture Evaluation"
              title="Architecture Evaluation"
              description="Organizations, including yours, have little to no idea about what their technical architecture is capable of. 

              Knowing your technical ecosystem’s potential is the first step towards improvement.  Take your organization to the next level by finding out where you are with our architecture evaluation. "
            />
          </div>
        </div>
      </section>

      {/* BOOK A CONSULTATION */}
      <Container component="section" className="flex flex-col items-center text-center mt-16 mb-32">
        <Heading component="h3" className="mt-16 mb-4 w-8/12">
          Start your technology journey with our cloud experts and see how far you'll go
        </Heading>

        <Paragraph className="max-w-2xl mb-8">The future of business is in the cloud.</Paragraph>

        <a href="mailto: hello@dyrector.io">
          <Button className="uppercase">Book a Consultation</Button>
        </a>
      </Container>

      <section id="packages" className="bg-gray-bg">
        <Container className="py-8 md:py-16 max-w-7xl mx-auto px-4 md:px-16 leading-10">
          <Heading component="h2" className="text-center" underline>
            Packages
          </Heading>
          <Paragraph className="mt-10 font-base ">
            dyrector.io is structured to help organizations achieve their digital transformation goals at various stages
            of Cloud continuum (private, public and hybrid); We work with our customers to choose the right cloud
            strategy and environments that address their specific business needs at any stage of the cloud journey.
          </Paragraph>

          <div className="flex flex-col mt-24 medium:mx-16 items-center">
            <div className="flex flex-col medium:flex-row">
              <LoveItem
                icon={LoveIcon}
                iconAlt="Managed Services"
                title="Managed Services"
                description="Remote Monitoring and Management of Cloud provider environments. 24x7 production support and SLA based response times. End to end management of cloud services across applications, middleware and databases."
              />
              <LoveItem
                icon={LoveIcon}
                iconAlt="Migrations"
                title="Migrations"
                description="On Premise Application to any Cloud provider. On Premise Application to hybrid. Database to Database including Old to New and Cross Platform (e.g Oracle to MYSQL)"
              />
              <LoveItem
                icon={LoveIcon}
                iconAlt="DevOps"
                title="DevOps"
                description="Cloud Design, Architecture and Implementation. Infrastructure-as-Code (IaC) implementations. Continuous Integration and Continuous Delivery (CI/CD) pipelines. Monitoring, Logging and Real Time Alerting."
              />
            </div>
            <div className="flex flex-col medium:flex-row">
              <LoveItem
                icon={LoveIcon}
                iconAlt="Cloud Native Development"
                title="Cloud Native Development"
                description="Development of scalable, resilient and secure applications built for the cloud leveraging its full power "
              />
              <LoveItem
                icon={LoveIcon}
                iconAlt="Kubernetes Clustering"
                title="Kubernetes Clustering"
                description="Make the potential of containers an operational reality and run containerized applications across multiple machines and environments. "
              />
              <LoveItem
                icon={LoveIcon}
                iconAlt="DevSecOps"
                title="DevSecOps"
                description="DevSecOps activities are performed to incorporate security controls into DevOps processes. It aims to solve security problems in the “fast” product/application delivery processes that come with DevOps."
              />
            </div>
          </div>
        </Container>
      </section>

      <section id="hire-us" className="py-8 md:py-16 font-base max-w-8xl mx-auto px-16 leading-10">
        <Heading component="h2" className="text-center" underline>
          Hire us
        </Heading>
        <div className="pt-8 pb-4 px-16 xl:px-64">
          <Heading component="h5">Dedicated Teams</Heading>
          <Paragraph className="mt-2">
            Dedicated DevOps engineers for infrastructure planning, testing and development. Build your own managed
            dedicated DevOps team and get engineers of any expertise and seniority.
          </Paragraph>
        </div>

        <div className="pt-8 pb-4 px-16 xl:px-64">
          <Heading component="h5">Fixed Price Model</Heading>
          <Paragraph className="mt-2">
            Outsource a predefined scope of tasks for your DevOps project. Hire DevOps Engineers on an hourly or
            full-time basis.
          </Paragraph>
        </div>

        <div className="pt-8 pb-4 px-16 xl:px-64">
          <Heading component="h5">DevOps Consultancy</Heading>
          <Paragraph className="mt-2">
            Get our expert assistance to create your own standardized DevOps solution for your business. Our DevOps
            consulting practices focus on the overall speed, scalability, and security of any software solution
          </Paragraph>
        </div>

        <Paragraph className="mt-10 font-base max-w-7xl mx-auto px-16 leading-10">
          If you do not see a DevOps solution for you, please get in touch and we will be happy to review your
          requirements and make a customised proposal.
        </Paragraph>
      </section>

      {/* TECHNICAL SKILL */}
      <section className="bg-gray-bg">
        <section id="skills" className="py-8 md:py-16 max-w-7xl mx-auto px-4 md:px-16 leading-10">
          <Heading component="h2" className="text-center" underline>
            Technical Skills
          </Heading>
          <Paragraph className="mt-10 font-base ">
            Our DevOps specialists at dyrector.io are experienced cloud engineers with high level of interest in the
            latest innovations regarding rapid delivery of quality software. Similar to DevOps, our teammates are
            on-boarded to new projects from pre-sales through delivery of the defined tools and processes to serve
            organization's business purposes. They analyse requirements and control the scope of the project. To us it's
            not only a necessity to stay up-to-date regarding cloud technologies, but also a matter of personal
            interests. After all, utilizing technologies is something we enjoy to do.
          </Paragraph>
          <div className="px-8">
            <Paragraph className="mt-12 mb-4 font-semibold">
              Our team's expertise consists of the following skills:
            </Paragraph>
            <SkillItem>
              Experience architecting, building and maintaining robust cloud environments in Azure, AWS, and GCP
            </SkillItem>
            <SkillItem>Container technologies, including Kubernetes, Docker, Podman, CRI-O</SkillItem>
            <SkillItem>Scripting and development skills in Bash, Python or Golang, etc.</SkillItem>
            <SkillItem>Networking and security troubleshooting regarding firewalls, routing, NAT, etc.</SkillItem>
            <SkillItem>Security runbooks, policy, and best practice documentation</SkillItem>
            <SkillItem>Azure and Kubernetes certifications such as architect and security</SkillItem>
          </div>
        </section>
      </section>

      {/* Partners */}
      <section className="bg-wave-atom py-8">
        <Container className=" max-w-full flex flex-col items-center left-0 right-0 mx-auto">
          <Paragraph className="text-blue-light font-light text-lg max-w-xl text-center py-4">
            dyrector.io is working with the following companies
          </Paragraph>

          <div className="flex flex-row flex-wrap justify-center mx-auto">
            <LabeledIcon icon={PentaIcon} iconAlt="Penta">
              Penta D.O.O.
            </LabeledIcon>
            <LabeledIcon icon={SuniliumIcon} iconAlt="Sunilium">
              Sunilium Ltd.
            </LabeledIcon>
            <LabeledIcon icon={MicrosoftIcon} iconAlt="Microsoft">
              Microsoft
            </LabeledIcon>
            <LabeledIcon icon={HanoverIcon} iconAlt="Hanover">
              Hanover Displays Ltd.
            </LabeledIcon>
            <LabeledIcon icon={IBMIcon} iconAlt="IBM">
              IBM Cloud
            </LabeledIcon>
            <LabeledIcon icon={BishopIcon} iconAlt="Bishop">
              Bishop & Co.
            </LabeledIcon>
            <LabeledIcon icon={NovatronicIcon} iconAlt="Novatron">
              Novatronic
            </LabeledIcon>
          </div>
        </Container>
      </section>

      {/* TEAM */}
      <section id="team" className="py-8 md:py-16 font-base max-w-8xl mx-auto px-16 leading-10">
        <Heading component="h2" className="text-center" underline>
          Team
        </Heading>
        <Paragraph className="mt-10 font-base max-w-7xl mx-auto px-16 leading-10">
          We're a Szeged, Hungary based team of DevOps experts dedicated to creating value to organizations with our
          DevOps-as-a-Service solutions. In the meantime, we're working on dyrector.io, the release management tool
          that'll innovate how developers and organizations think about deployments.
        </Paragraph>

        <div className="pt-8 pb-4 px-16 xl:px-64">
          <Heading component="h5">Security is golden</Heading>
          <Paragraph className="mt-2">
            The security of your business is our top priority. Without secure software there's no secure business, and
            vice versa. Along the way of optimizing your workflows and tools to serve more efficient DevOps practices,
            we're always bearing security requirements in mind.
          </Paragraph>
        </div>

        <div className="pt-8 pb-4 px-16 xl:px-64">
          <Heading component="h5">We're lean & adaptive</Heading>
          <Paragraph className="mt-2">
            Our partners find value in working with us because we're striving for the greatest possible impact with the
            least of resources. At the same time, we're looking for ways to deliver improvements as frequently as we can
            to make our clients' work easier.
          </Paragraph>
        </div>

        <div className="pt-8 pb-4 px-16 xl:px-64">
          <Heading component="h5">We're seeking growth</Heading>
          <Paragraph className="mt-2">
            Your business deserves to grow – both by technical tools and knowledge. Our experience would be meaningless
            without keeping up with the latest innovations of DevOps tools and cloud technologies. Therefore, we are
            always looking for better newest solutions to deliver value to our partners and support their efforts for
            digital transformation.
          </Paragraph>
        </div>
      </section>

      <Contact></Contact>

      {/* Partners */}
      <section className="bg-wave-atom py-8">
        <Container className=" max-w-full flex flex-col items-center left-0 right-0 mx-auto">
          <Paragraph className="text-blue-light font-light text-lg max-w-xl text-center py-4">
            Find out more about our services below
          </Paragraph>

          <div className="flex flex-row flex-wrap justify-center mx-auto">
            <LabeledIcon link="https://clutch.co/profile/dyrectorio#highlights" icon={ClutchIcon} iconAlt="Penta">
              Clutch
            </LabeledIcon>
            <LabeledIcon
              link="https://www.crunchbase.com/organization/dyrector-io"
              icon={CrunchBaseIcon}
              iconAlt="Sunilium"
            >
              CrunchBase
            </LabeledIcon>
            <LabeledIcon link="https://dreamjobs.hu/hu/company/dyrectorio" icon={DreamJobsIcon} iconAlt="Microsoft">
              Dreamjobs
            </LabeledIcon>
            <LabeledIcon
              link="https://superbcompanies.com/categories/azure-consulting-companies/"
              icon={SuperBIcon}
              iconAlt="Hanover"
            >
              SuperbCompanies
            </LabeledIcon>
          </div>
        </Container>
      </section>

      <AreYouCuriousAboutHowWeWork></AreYouCuriousAboutHowWeWork>
    </Layout>
  )
}

export default Index
