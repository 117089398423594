import clsx from 'clsx'
import * as React from 'react'

export interface ContainerProps extends React.HTMLProps<HTMLDivElement> {
  component?: string
}

export function Container({
  component = 'div',
  className,
  children,
  ...props
}: ContainerProps) {
  return React.createElement(
    component,
    {
      ...props,
      className: clsx(
        'container mx-auto px-6 w-full max-w-2xl md:max-w-5xl lg:max-w-7xl',
        className,
      ),
    },
    children,
  )
}
