import clsx from 'clsx'
import * as React from 'react'

export function Column({className, ...props}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={clsx('flex flex-col items-center text-left', className)}
      {...props}
    />
  )
}
