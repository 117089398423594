import React, {ReactElement} from 'react'
import {Container} from '../../components/atoms/container'
import {Heading} from '../../components/atoms/heading'
import {SocialBigIcon} from '../../components/molecules/social-big-icon'
import FacebookIcon from '../../images/icons/social/facebook-big.svg'
import InstaIcon from '../../images/icons/social/insta-big.svg'
import LinkedInIcon from '../../images/icons/social/linkedin-big.svg'
import TwitterIcon from '../../images/icons/social/twitter-big.svg'

export function AreYouCuriousAboutHowWeWork(): ReactElement {
  return (
    <Container className="text-center my-20">
      <Heading component="h2">Are you curios about how we work?</Heading>
      <div className="my-4 text-center text-purple-lightText font-semibold">
        Read more about us and check out our social feeds.
      </div>
      <div className="flex flex-row items-center justify-center mt-16">
        <SocialBigIcon
          icon={FacebookIcon}
          iconAlt="Facebook social icon"
          toLink="https://www.facebook.com/Dyrectorio-101961421856809"
        ></SocialBigIcon>
        <SocialBigIcon
          icon={InstaIcon}
          iconAlt="Insta social icon"
          toLink="https://instagram.com/dyrectorio"
        ></SocialBigIcon>
        <SocialBigIcon
          icon={TwitterIcon}
          iconAlt="Twitter social icon"
          toLink="https://twitter.com/dyrectorio"
        ></SocialBigIcon>
        <SocialBigIcon
          icon={LinkedInIcon}
          iconAlt="LinkedIn social icon"
          toLink="https://www.linkedin.com/company/dyrectorio"
        ></SocialBigIcon>
      </div>
    </Container>
  )
}
