import React, {ReactElement} from 'react'
import {Helmet} from 'react-helmet'

export function MetaHelmet(): ReactElement {
  return (
    <Helmet
      htmlAttributes={{lang: 'en'}}
      link={[{rel: 'canonical', href: 'https://dyrector.io'}]}
    >
      <meta charSet="utf-8" />
      <title>
        dyrector.io - Software delivery on the go without DevOps Specialists
      </title>
      <meta
        name="description"
        content="Our great mission to help people understand DevOps and give them a tool that they can spare a lot of energy, money and time with. "
      />
      <meta
        name="keywords"
        content="release,management,devops,docker,kubernetes,agent,jira"
      />
      <meta property="og:title" content="dyrector.io" />
      <meta property="og:type" content="article" />
      <meta property="og:url" content="https://dyrector.io" />
      <meta
        property="og:image"
        content="https://dyrector.io/static/809f32056da442775de4876cfb542903/c3cd8/home-hero-illustration.webp"
      />
      <meta
        property="og:description"
        content="Software delivery on the go without DevOps Specialists"
      />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://dyrector.io" />
    </Helmet>
  )
}
