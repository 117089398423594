import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React, { ReactElement } from 'react'
import { Button } from '../atoms/button'

export function Navbar(): ReactElement {
  return (
    <nav className="flex flex-row items-center space-x-14 justify-between">
      <ul className="list-none flex flex-row space-x-14 p-3">
        <li>
          <AnchorLink to="/#home">Home</AnchorLink>
        </li>
        <li>
          <AnchorLink to="/#services" title="Services"></AnchorLink>
        </li>
        <li>
          <AnchorLink to="/#packages">Packages</AnchorLink>
        </li>
        <li>
          <AnchorLink to="/#skills">Skills</AnchorLink>
        </li>
        <li>
          <AnchorLink to="/#team">Team</AnchorLink>
        </li>
      </ul>

      <div className="space-x-6">
        <AnchorLink to="/#contact">
          <Button>Contact</Button>
        </AnchorLink>
      </div>
    </nav>
  )
}
