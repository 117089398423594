import clsx from 'clsx'
import * as React from 'react'

export interface LabeledIconProps {
  icon?: any
  link?: string
  iconAlt: string
  labelClassName?: string
}

export function LabeledIcon({
  icon,
  iconAlt,
  link,
  children,
  labelClassName,
}: React.PropsWithChildren<LabeledIconProps>) {
  return (
    <div className="flex justify-center w-8/12 sm:w-1/12 m-8">
      <a href={link} target="_blank" rel="noreferrer">
        <img src={icon} alt={iconAlt} />
        <span className={clsx('text-white text-center max-w-min', labelClassName)}>{/* {children} */}</span>
      </a>
    </div>
  )
}
