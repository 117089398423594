import clsx from 'clsx'
import * as React from 'react'

export function Row({className, ...props}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={clsx(
        'flex flex-row flex-wrap lg:flex-nowrap justify-between items-center text-left',
        className,
      )}
      {...props}
    />
  )
}
