import clsx from 'clsx'
import * as React from 'react'

export type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export interface HeadingProps extends React.HTMLProps<HTMLHeadingElement> {
  /**
   * Determines the rendered tag.
   */
  component?: HeadingVariant
  /**
   * Determines how the heading should appear without changing the rendered tag.
   */
  variant?: HeadingVariant
  /**
   * Determines whether heading should have a decorative underline or not.
   */
  underline?: boolean
  subTitle?: subTitle
}

interface subTitle {
  first: string
  second?: string
}

export function Heading({
  component = 'h1',
  variant,
  children,
  underline,
  subTitle,
  ...props
}: React.PropsWithChildren<HeadingProps>) {
  let fontSizeClass
  const actualVariant = variant ?? component

  if (actualVariant === 'h1') {
    fontSizeClass = 'text-5xl'
  } else if (actualVariant === 'h2') {
    fontSizeClass = 'text-4xl'
  } else if (actualVariant === 'h3') {
    fontSizeClass = 'text-3xl'
  } else if (actualVariant === 'h4') {
    fontSizeClass = 'text-2xl'
  } else if (actualVariant === 'h5') {
    fontSizeClass = 'text-xl'
  } else {
    fontSizeClass = 'text-lg'
  }

  return React.createElement(
    component,
    {
      ...props,
      className: clsx(
        props.className,
        `${fontSizeClass} font-extrabold text-blue`,
      ),
    },
    <>
      {children}{' '}
      {underline && <span className="block h-1.5 bg-menta mt-4 w-24 mx-auto" />}
      {subTitle ? (
        <div className="mt-8 text-base font-normal text-center text-gray">
          {subTitle.first}
          <br />
          {subTitle.second}
        </div>
      ) : (
        ''
      )}
    </>,
  )
}
