import * as React from 'react'
import SkillIcon from '../../images/icons/skill-logo.svg'


export interface SkillItemProps {
  labelClassName?: string
}

export function SkillItem({
  children,
  labelClassName,
}: React.PropsWithChildren<SkillItemProps>) {
  return (
    <div className="flex text-gray py-2">
      <img src={SkillIcon} alt="skill-icon-list-item" className='px-2' />
      <span className=''>
        {children}
      </span>
    </div>
  )
}
