import clsx from 'clsx'
import * as React from 'react'

export interface ButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'type'> {
  type?: 'button' | 'submit' | 'reset'
  variant?: 'primary' | 'secondary'
  component?: string | React.ElementType
  to?: string
}

export function Button({
  children,
  className,
  variant = 'primary',
  type = 'button',
  component = 'button',
  ...props
}: React.PropsWithChildren<ButtonProps>) {
  const additionalProps =
    component === 'button' ? {...props} : {omitFontWeight: true, ...props}

  return React.createElement(
    component,
    {
      ...additionalProps,
      className: clsx(
        variant === 'primary'
          ? 'bg-purple hover:bg-purple-dark text-white'
          : 'bg-transparent text-grey-dark border-purple border-2',
        'rounded-full text-center text-sm w-max px-9 py-3 font-medium whitespace-nowrap transition-colors',
        className,
      ),
    },
    children,
  )
}
