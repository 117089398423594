import * as React from 'react'

export interface SocialBigIconProps {
  icon: any
  iconAlt: string
  toLink: string
  labelClassName?: string
}

export function SocialBigIcon({
  icon,
  iconAlt,
  toLink,
}: React.PropsWithChildren<SocialBigIconProps>) {
  return (
    <a href={toLink} target="_blank" rel="noreferrer">
      <div className="flex h-24 w-24 justify-center items-center p-6 mx-4 bg-purple-gradientDark rounded-full max-w-1 box-border">
        <img src={icon} alt={iconAlt} />
      </div>
    </a>
  )
}
