import clsx from 'clsx'
import * as React from 'react'
import {Paragraph} from '../atoms/paragraph'

export interface LoveItemProps {
  icon?: any
  iconAlt: string
  labelClassName?: string
  title: string
  description: string
}

export function LoveItem({
  icon,
  iconAlt,
  labelClassName,
  title,
  description,
}: React.PropsWithChildren<LoveItemProps>) {
  return (
    <div className="flex-1 flex-wrap p-4 items-center md:items-start">
      <img className="mb-8" src={icon} alt={iconAlt} width={52} />
      <span
        className={clsx(
          'text-blue-dark text-2xl text-left font-semibold',
          labelClassName,
        )}
      >
        {title}
      </span>

      <Paragraph className="text-left">{description}</Paragraph>
    </div>
  )
}
