import React, {ReactElement} from 'react'
import CookieConsent from 'react-cookie-consent'

export function Cookie(): ReactElement {
  return (
    <CookieConsent
      style={{background: '#DCE2F3', color: '#02164B', padding: '20px'}}
      buttonStyle={{
        background: '#771BDF',
        color: 'white',
        fontSize: '14px',
        borderRadius: '25px',
        padding: '16px 25px',
      }}
      buttonText="Agree"
    >
      This website uses cookies to personalize content and analyse traffic in
      order to offer you a better experience. Fore more information check out
      the
      <a href="https://dyrector.io/policies/cookie-policy"> coockie policy</a> site.
    </CookieConsent>
  )
}
