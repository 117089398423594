import clsx from 'clsx'
import {GatsbyLinkProps, Link as GatsbyLink} from 'gatsby'
import React from 'react'

export interface LinkProps<T extends {}> extends GatsbyLinkProps<T> {
  omitFontWeight?: boolean
  newTab?: boolean
}

export function Link({
  className,
  activeClassName,
  to,
  newTab,
  children,
  omitFontWeight,
}: LinkProps<{}>) {
  return (
    <GatsbyLink
      className={clsx(!omitFontWeight && 'font-semibold', className)}
      activeClassName={clsx(
        'border-b-2 border-menta-transparent',
        activeClassName,
      )}
      to={to}
      target={newTab ? '_blank' : '_self'}
    >
      {children}
    </GatsbyLink>
  )
}
